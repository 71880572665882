<template>
  <v-container fill-height :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row justify="center">
      <v-col xs="12" sm="10" md="6" lg="5">
        <v-card class="elevation-12">
          <v-row>
            <v-card-title primary-title class="cardtext">
              <div>
                <h3 class="headline">Welcome, {{ firstName }} {{ lastName }}</h3>
              </div>
            </v-card-title>
            <v-spacer />
            <v-card-actions class="logout">
              <v-btn color="primary" @click="logout">log out</v-btn>
            </v-card-actions>
          </v-row>
          <v-divider class="ma-4" />
          <v-card-title class="headline">Last views</v-card-title>
          <v-data-table
            :items="routes"
            hide-default-footer
            :headers="headers"
            sort-by2="when"
            sort-desc2
          >
            <template v-slot:item.path="{ item }">
              <router-link :to="item.path">{{item.path}}</router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VBtn,
  VCard,
  VCardActions,
  VCardTitle,
  VCol,
  VContainer,
  VDataTable,
  VDivider,
  VRow,
  VSpacer,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import user from "../../components/external/modules/user/store/constants";
import path from "../../shared/paths";
import dhf from "../../components/external/modules/shared/utils/datehourformat";

const maxLastViews = 10;

export default {
  components: {
    VBtn,
    VCard,
    VCardActions,
    VCardTitle,
    VCol,
    VContainer,
    VDataTable,
    VDivider,
    VRow,
    VSpacer,
  },

  data: () => ({
    headers: [
      { value: "path", text: "path" },
      { value: "when", text: "when" },
    ],
  }),

  computed: {
    ...mapGetters({
      user: user.get.CURRENT_USER,
      expiration: user.get.EXPIRATION,
    }),

    firstName() {
      if (this.user) {
        return this.user.firstName;
      }
      return "";
    },

    lastName() {
      if (this.user) {
        return this.user.lastName;
      }
      return "";
    },

    routes() {
      // gathering all the last views
      let elems = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith("path_last_view_")) {
          elems.push({
            path: key,
            when: dhf(localStorage.getItem(key), true),
          });
        }
      }

      // now, sorting up with the date
      elems.sort(function (el1, el2) {
        if (el1.when < el2.when) {
          return 1;
        }
        return -1;
      });

      // cleaning up the local storage to avoid storing too much data over time
      for (let i = maxLastViews; i < elems.length; i++) {
        localStorage.removeItem(elems[i].path);
      }

      // filtering to only keep the first elements
      elems = elems.slice(0, maxLastViews);

      // tweaking the paths to remove the prefix
      elems.forEach(function (el) {
        el.path = el.path.substring("path_last_view_".length);
      });

      // returning
      return elems;
    },
  },

  methods: {
    logout() {
      const self = this;
      this.$store.dispatch(user.act.LOGOUT).finally(() => {
        self.$router.push(path.LOGIN);
      });
    },
  },
};
</script>

<style>
.cardtext {
  margin-left: 12px;
}
.logout {
  margin-right: 20px;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: medium;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: medium;
}
</style>
